define("builder/pods/website/edit/page/edit/route", ["exports", "builder/routes/application", "builder/core/enumerators/controller-action", "builder/core/enumerators/module", "builder/config/environment"], function (_exports, _application, _controllerAction, _module, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Service for feature toggling
     * @type {Ember.Service}
     */
    features: Ember.inject.service('features'),

    /**
     * Module store service
     * @type {ModulesStoreService}
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * Structure service
     * @type {StructureService}
     */
    structureService: Ember.inject.service('structure'),

    /**
     * Service responsible for grid columns resize in Desktop and Small monitor devices
     * @type {ResizeGridColumnsService}
     */
    resizeGridColumnsService: Ember.inject.service('resize-grid-columns'),

    /**
     * Service responsible for grid modules resize
     * @type {ResizeGridModuleService}
     */
    resizeGridModuleService: Ember.inject.service('resize-grid-module'),

    /**
     * Drag and drop service
     * @type {DragAndDropService}
     */
    dragAndDropService: Ember.inject.service('drag-and-drop'),

    /**
     * Grid components factory service, kinda
     * @type {GridBuilderService}
     */
    gridBuilderService: Ember.inject.service('grid-builder'),

    /**
     * History service
     * @type {HistoryService}
     */
    history: Ember.inject.service('history'),

    /**
     * Access control service
     * @type {AccessControlService}
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Form add field service
     * @type {FormAddFieldService}
     */
    formAddFieldsService: Ember.inject.service('form-add-field'),

    /**
     * Service to manipulate code editor popups
     * @type {FieldCodeService}
     */
    fieldCodeService: Ember.inject.service('field-code'),

    /**
     * Preview service
     * @type {Ember.Service}
     */
    previewService: Ember.inject.service('preview'),

    /**
     * @inheritdoc
     */
    model(params, transition) {
      const existingPage = this.store.peekRecord('page', params.page_id);

      if (!existingPage || existingPage.isDeleted) {
        return this.gotoNotFoundPage(transition);
      }

      const matchedRecord = this.store.peekAll('page-meta').findBy('PageId', existingPage.id);
      this.store.getPageMeta(matchedRecord.id).then(updatedPageMeta => {
        matchedRecord.Structure = updatedPageMeta.data.attributes.Structure;
        matchedRecord.set('hasDirtyAttributes', false);
      });
      const matchedSeoRecord = this.store.peekAll('page-seo').findBy('PageId', existingPage.id);
      this.store.getPageSeo(matchedSeoRecord.id).then(updatedPageSeo => {
        matchedSeoRecord.Structure = updatedPageSeo.data.attributes.Structure;
        matchedSeoRecord.set('hasDirtyAttributes', false);
      });

      if (existingPage.BaseStructure == null) {
        return this.store.getPageStructure(existingPage.id).then(updatedPage => {
          existingPage.BaseStructure = updatedPage.BaseStructure;
          existingPage.Structure = updatedPage.Structure; //	existingPage.set('hasDirtyAttributes', false);

          return this._resetLayoutModel(existingPage);
        }).catch(error => {
          window.console.error('Error fetching page:', error);
        });
      } else {
        return this._resetLayoutModel(existingPage);
      }
    },

    /**
    * @inheritDoc
     */
    async beforeModel() {
      await appBootstrap.loadCSS({
        id: _environment.default.APP.E6_BOOTSTRAP_CSS_PREFIX,
        href: this.previewService.bootstrapUrl
      });
    },

    /**
     * Model hook handler for Builder page
     *
     * @param {PageModel} model - page model
     * @returns {Object} - {all: <PageModel[]>, page: <PageModel>, website: <WebsiteModel>}
     */
    modelHookForBuilderPage(model) {
      const websiteId = parseInt(this.paramsFor('website.edit').website_id, 10);
      const layouts = this.store.peekAll('website-layout');
      const layout = layouts.find(record => record.Name === model.WebsiteLayoutName);
      return {
        all: this.store.peekAll('page'),
        page: model,
        masterPage: this.store.peekRecord('page-global', model.GlobalParentId),
        forms: this.store.peekAll('form'),
        layout,
        website: this.store.peekRecord('website', websiteId),
        themes: this.store.peekAll('module-theme'),
        modules: this.store.peekAll('module'),
        modulesStyles: this.store.peekAll('module-style'),
        websiteStyle: this.modelFor('website.edit').styles,
        websiteFonts: this.modelFor('website.edit').websiteFonts,
        formFields: this.store.peekAll('form-field-type'),
        formFieldCategories: this.store.peekAll('form-field-type-category')
      };
    },

    /**
     * Redirect to external url
     * NOTE: this method isn't tested
     *
     * @param {string} url - url to external page
     */
    redirectToExternalUrl(url) {
      window.location.href = url;
    },

    /**
     * @inheritdoc
     */
    afterModel(model, transition) {
      if (!model || !model.page) {
        return this.gotoNotFoundPage(transition);
      }

      this.structureService.initializeViewModel(model.page);
      const ids = this.findFormConfigIds(this.structureService.pageViewModel);
      this.modules.clearCoreModules(); // Modules must be filled in before controller is ready(setupController hook), since open config is required sometimes

      this.modules.fill(this.structureService.pageViewModel.originObject.children);
      this.fillSystemModules();
      this.configurationService.set('page', model.page);
      model.structure = this.structureService.pageViewModel;
      this.prepareDragAndDropModel(model.page);
      this.formAddFieldsService.set('fields', model.formFields);
      return this._findRecordsByIds('page-module', ids).then(modules => this._findRecordsByIds('form', modules.getEach('Structure.formId')));
    },

    /**
     * Add system modules to modules service store
     */
    fillSystemModules() {
      const systemModules = this.store.peekAll('system-module');
      const gridBuilder = this.gridBuilderService;
      systemModules.forEach(module => {
        if (module.IsActive) {
          const theme = module.selectedTheme;
          const structure = gridBuilder.createInstance(_module.default.MODULE_SYSTEM, {
            id: module.ModuleConfigurationId,
            theme: theme.Name,
            name: module.Module
          });
          this.modules.add(structure);
        }
      });
    },

    /**
     * Redirect to not found page
     *
     * @method gotoNotFoundPage
     * @param {Transition} transition
     */
    gotoNotFoundPage(transition) {
      transition.abort();
      this.transitionTo('not-found', 'not-found');
    },

    addFormQueue(queue, children) {
      queue.push(children);
    },

    /**
     * Finds form core-modules ids
     *
     * @param {Object} structure - page structure
     * @returns {string[]} array of form ids
     */
    findFormConfigIds(structure) {
      const ids = [];
      const queue = [];
      let next = structure;

      while (next) {
        const children = Ember.isArray(next) ? next : next.children;

        if (children) {
          children.forEach(this.addFormQueue.bind(this, queue));
        }

        if (next.type === _module.default.MODULE_CORE && next.name === 'Form') {
          ids.push(next.id);
        }

        next = queue.shift();
      }

      return ids;
    },

    /**
     * @inheritdoc
     */
    setupController(controller, model, ...args) {
      const websiteEditModel = this.modelFor('website.edit');

      this._super(controller, model, ...args);

      this.send('handleBubbleAction', _controllerAction.default.CLEAR_UNDO_REDO);
      this.send('handleBubbleAction', _controllerAction.default.CLOSE_MENU_MANAGEMENT);
      this.setDragAndDropModel(controller);
      controller.configurationService.set('hasUnsavedChanges', websiteEditModel.menu.hasDirtyAttributes);
    },

    /**
     * Prepares page structure
     * @param {Object} model - This route model
     */
    prepareDragAndDropModel(page) {
      const modules = this.modules._list;
      modules.forEach(module => {
        this._injectModuleStyleModel(module);
      });
      this.dragAndDropService.setDraggableElements();
      this.resizeGridColumnsService.setResizableElements();
      this.resizeGridModuleService.setResizableElements(this.structureService.pageViewModel);

      this._setPageRestrictions(page, this.structureService.pageViewModel);
    },

    /**
     * Set page structure for D&D
     *
     * @method setDragAndDropModel
     * @param {Ember.Controller} controller - page/edit/controller
     * @param {Ember.Object} structure - Prepared page structure
     */
    setDragAndDropModel(controller) {
      controller.reactivateActiveModule(); // Show page tools panel

      this.send('handleBubbleAction', _controllerAction.default.SHOW_PAGE_TOOLS_PANEL);
    },

    /**
     * @inheritdoc
     */
    resetController(controller, isExiting) {
      if (controller.configurationService.hasUnsavedChanges) {
        this.send('rollbackModels');
      }

      controller.model.layout.lockLayout();
      this.controllerFor('website/edit/page').set('menuManagementDropDownIsOpened', false); // Clear all modules

      if (isExiting) {
        appBootstrap.unloadCSS(_environment.default.APP.E6_BOOTSTRAP_CSS_PREFIX);
        this.modules.clearCoreModules();
      }
    },

    /**
     * Reset layouts model if has dirty attributes
     *
     * @method resetLayoutsModel
     * @param {Ember.Model[]} layouts - array with layouts model
     */
    resetLayoutsModel(layouts) {
      layouts.forEach(layout => {
        if (layout.hasDirtyAttributes) {
          layout.rollbackAttributes();
        }
      });
    },

    /**
     * @inheritDoc
     */
    deactivate() {
      // Closing the widget popup manually, because widget is not have dedicated route
      this.controller.send('closeWidgetPopup');
    },

    /**
     * TODO: this method must be removed, since that way is not very productive
     * since it will query entities 1 by one, but we must query all entities at once
     *
     * @param {String} modelName - model name to find
     * @param {Array} ids - ids to find
     * @returns {Promise}
     * @private
     */
    _findRecordsByIds(modelName, ids = []) {
      const promises = [];

      for (let i = 0, len = ids.length; i < len; i++) {
        promises.push(Ember.RSVP.Promise(() => this.store.findRecord(modelName, ids[i])));
      }

      return Ember.RSVP.all(promises);
    },

    /**
     * Set page restrictions if any
     * Here we lock oem page for users who has no OEM edit acls
     *
     * @param {PageModel} page - page model
     * @param {EmberObject} structure - page model structure, prepared for render
     * @private
     */
    _setPageRestrictions(page, structure) {
      const updatePageIsGranted = this.accessControl.can('update page', page).isGranted; // React only if update page access is revoked

      if (!updatePageIsGranted) {
        const bodyStructure = structure.get('originObject.children.1');
        bodyStructure.set('isEnabled', updatePageIsGranted);
        bodyStructure.set('droppable', updatePageIsGranted);

        this._walk(bodyStructure, node => {
          node.set('draggable', updatePageIsGranted);
        });
      }
    },

    /**
     * Method provide some additional dependencies for module style model
     *
     * @param {Object} moduleStructure - our grid component of module type
     * @method _injectModuleStyleModel
     * @private
     */
    _injectModuleStyleModel(moduleStructure) {
      const moduleStyleModel = this.store.peekAll('module-style').findBy('id', moduleStructure.originObject.id.toString());

      if (!moduleStyleModel) {
        return;
      }

      const moduleTheme = this.store.peekRecord('module-theme', moduleStyleModel.ThemeId);

      if (moduleTheme) {
        moduleStyleModel.set('styleMap', moduleTheme.StyleMap);
      } else {
        // If the module style is found but the module theme is not found,
        // then the theme probably is switched off in Ignite.
        moduleStructure.set('isEnabled', false);
      }

      moduleStyleModel.set('globalVariablesModel', this.modelFor('website.edit').styles);
      moduleStructure.set('moduleStyleModel', moduleStyleModel);
    },

    /**
     * Walk page structure and invoke callback on each node
     *
     * @param {EmberObject} node - root node where to start
     * @param {Function} callback - callback to be invoked on each visited node
     * @private
     */
    _walk(node, callback) {
      callback(node);
      const nodeChildren = node.originObject.children;

      if (!nodeChildren || !nodeChildren.length) {
        return;
      }

      nodeChildren.forEach(childNode => this._walk(childNode, callback));
    },

    _resetLayoutModel(page) {
      const layouts = this.store.peekAll('website-layout');

      if (page.isChild) {
        const masterPage = this.store.peekRecord('page-global', page.GlobalParentId);

        if (!masterPage) {
          return this.store.findRecord('page-global', page.GlobalParentId).then(() => {
            this.resetLayoutsModel(layouts);
            return this.modelHookForBuilderPage(page);
          });
        }
      } else {
        this.resetLayoutsModel(layouts);
        return this.modelHookForBuilderPage(page);
      }
    },

    _initializePageViewModel(page) {
      this.structureService.initializeViewModel(page);
      const {
        children
      } = this.structureService.pageViewModel.originObject;
      this.modules.clearCoreModules();
      this.modules.fill(children);
      this.prepareDragAndDropModel(page);
      this.setDragAndDropModel(this.controller);
    },

    actions: {
      willTransition() {
        this.fieldCodeService.codeEditCancel();
      },

      addModuleHistoryCheckpoint(structure, type, data) {
        if (type === 'config') {
          this.controller.structureAndConfigCheckpoint(structure, data);
        } else if (type === 'page') {
          this.controller.send('createStructureCheckpoint', structure, data);
        }
      },

      createHistoryCheckpoint(...data) {
        this.controller.createHistoryCheckpoint(...data);
      },

      setQueryParams(params) {
        this.transitionTo({
          queryParams: params
        });
      },

      invalidateSettingsId() {
        this.controller.invalidateSettingsId();
      },

      setActiveModule(widget) {
        this.controller.setActiveModule(widget);
      },

      deactivateModule() {
        this.controller.send('deactivateModule');
      },

      reloadActiveModule() {
        this.controller.reloadModule();
      },

      setChangesInPage(type) {
        this.controller.send('setChanges', type);
      },

      removeModule(module) {
        this.controller.removeModule(module);
      },

      moduleAction(...args) {
        this.controller.send('moduleAction', ...args);
      },

      /**
       * Action to reset page D&D structure
       * @param {Boolean} [force=false] - force update page structure
       */
      resetPageStructure(force = false) {
        if (force || this.controller.configurationService.hasUnsavedChanges) {
          const {
            page
          } = this.controller.model;

          if (page.BaseStructure == null) {
            return this.store.getPageStructure(page.id).then(updatedPage => {
              page.BaseStructure = updatedPage.BaseStructure;
              page.Structure = updatedPage.Structure;

              this._initializePageViewModel(page);
            });
          } else {
            this._initializePageViewModel(page);
          }
        }
      },

      unlockLayout(widget) {
        this.controller.send('unlockLayout', widget);
      },

      handleBubbleAction(type) {
        switch (type) {
          case _controllerAction.default.OPEN_GLOBAL_STYLES:
          case _controllerAction.default.OPEN_MODULE_GALLERY:
          case _controllerAction.default.OPEN_MARKET_SEGMENTS_SETTINGS:
          case _controllerAction.default.OPEN_PAGE_SETTINGS:
          case _controllerAction.default.OPEN_SITE_SETTINGS:
            {
              // Close if open widget config
              this.controller.send('closeWidgetPopup');
              this.fieldCodeService.codeEditCancel();
              return true;
            }

          default:
            {
              return true;
            }
        }
      },

      setChanges() {
        // Bubble action to upper route
        return true;
      }

    }
  });

  _exports.default = _default;
});