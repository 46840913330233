define("builder/pods/website/edit/route", ["exports", "builder/routes/application", "builder/config/environment", "builder/core/enumerators/engine", "builder/core/enumerators/cookie"], function (_exports, _application, _environment, _engine, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Service for feature toggling
     * @type {Ember.Service}
     */
    features: Ember.inject.service(),

    /**
     * Preview service
     * @type {PreviewService}
     */
    previewService: Ember.inject.service('preview'),

    /**
     * Global configuration service
     * @type {ConfigurationService}
     */
    configurationService: Ember.inject.service('configuration'),

    /**
     * Modules store
     * @type {ModulesStoreService}
     */
    modules: Ember.inject.service('modules-store'),

    /**
     * Company ans website settings service
     * @type {CompanyAndWebsiteService}
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * Ember ajax service
     * @type {AjaxService}
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * Service to manage font icons state
     * @type {IFontIconService}
     */
    fontIconService: Ember.inject.service('font-icon'),

    /**
     * Contain scss variables object
     * @type {Object}
     */
    websiteStylesMap: null,

    /**
     * @inheritDoc
     */
    beforeModel() {
      if (this.app.websites.length) {
        return this.store.findAll('website');
      } // Non-promise return values are not utilized in any way.
      // See https://api.emberjs.com/ember/3.11/classes/Route/methods/transitionTo?anchor=beforeModel


      return null;
    },

    /**
     * @inheritDoc
     */
    model(params, transition) {
      const websiteId = params.website_id;
      const igniteWebsite = this.app.websites.filterBy('id', websiteId).pop();
      const builderWebsite = this.store.peekRecord('website', websiteId);
      const canEditFormFields = this.accessControl.can('edit fields form').isGranted;

      if (igniteWebsite) {
        this.send('setWebsite', websiteId);
      }

      if (!builderWebsite && !igniteWebsite) {
        transition.abort();
        this.transitionTo('not-found', 'not-found');
        return null;
      }

      if ((igniteWebsite === null || igniteWebsite === void 0 ? void 0 : igniteWebsite.engine) === _engine.E5) {
        transition.abort();
        this.transitionTo('website.e6-info');
        return null;
      }

      if (!builderWebsite && igniteWebsite) {
        transition.abort();
        this.transitionTo('website.create');
        return null;
      } // Bug fix when user try to switch to another website.
      // When we make request for pages list by website id, we probably can remove it.
      // Also need to unload website-specific models (e.g. module-styles, page-meta, website-setting)


      this._unloadAllModels(['page', 'page-seo', 'page-meta', 'form', 'website_layout', 'website-style', 'meta-variables', 'website-setting', 'module-style', 'website-font', 'menu', 'container-template-category', 'container-template', 'system-module', 'form-field-type', 'form-field-type-category', 'module-category-item', 'module-theme', 'website-config']);

      return Ember.RSVP.hash({
        website: this.store.findRecord('website', websiteId, {
          reload: true
        }),
        metaVariables: this.store.findRecord('meta-variables', websiteId, {
          reload: true
        }),
        websiteSetting: this.store.findRecord('website-setting', websiteId, {
          reload: true
        }),
        systemModules: this.store.findAll('system-module'),
        layouts: this.store.findAll('website_layout'),
        pages: this.store.findAll('page'),
        styles: this.store.findRecord('website-style', websiteId),
        stylesMap: this._getWebsiteStylesMap(),
        pagesMetaTags: this.store.findAll('page-meta'),
        pagesSeo: this.store.findAll('page-seo'),
        websiteFonts: this.store.findAll('website-font'),
        formTemplates: this.store.findAll('form'),
        modulesStyles: this.store.findAll('module-style'),
        containerTemplateCategories: this.store.findAll('container-template-category'),
        containerTemplates: this.store.findAll('container-template'),
        categoryItems: this.store.findAll('module-category-item'),
        themes: this.store.findAll('module-theme'),
        websiteConfig: this.store.findRecord('website-config', websiteId, {
          reload: true
        }),
        // Cross website/company models. Reloaded if needed (see corresponding adapters of models)
        modules: this.store.findAll('module'),
        widgets: this.store.findAll('widget'),
        categories: this.store.findAll('module-category'),
        formFields: canEditFormFields ? this.store.findAll('form-field-type') : null,
        formFieldCategories: canEditFormFields ? this.store.findAll('form-field-type-category') : null,
        languages: this.store.findAll('language'),
        icons: this.fontIconService.reloadIcons(),
        bullets: this.store.findAll('bullet')
      });
    },

    /**
     * Unload all models from store
     * @param {string[]} modelNames - model names
     * @returns {void}
     * @private
     */
    _unloadAllModels(modelNames) {
      if (!modelNames || !modelNames.length) {
        return;
      } // Method model.unloadRecord more stability and synchronously unload record then method store.unloadAll


      modelNames.forEach(modelName => {
        this.store.peekAll(modelName).forEach(model => model.unloadRecord());
      });
    },

    /**
     * Method get from API styles object,
     * which contain global scss variables and rules how to build css.
     * Received map cached in route.
     * @returns {Promise}
     * @private
     */
    _getWebsiteStylesMap() {
      let result;

      if (!this.websiteStylesMap) {
        result = this.ajaxService.request(`${_environment.default.apiURL}/init_stylemap?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configurationService.sessionId')}`).then(map => {
          this.set('websiteStylesMap', map);
          return map;
        }).catch(err => new Ember.Error(err));
      } else {
        result = this.websiteStylesMap;
      }

      return result;
    },

    /**
     * @inheritDoc
     */
    afterModel(model) {
      // We need to apply currently selected website icons font on each website change
      this.fontIconService.applyInitialIconFont(); // Share website

      this.configurationService.set('website', this.store.peekRecord('website', model.website.get('id'))); // Dependencies are built right after all required data(modules and themes) is loaded

      this.buildModulesDependencies(this.get('modules.dependencyManager'), model.modules, model.themes); // Setup style map for website styles

      model.styles.set('styleMap', model.stylesMap); // Init required dependencies for module style models

      this._provideModuleStylesModelDependencies(model.modulesStyles, model.styles);

      return this.store.findRecord('menu', model.website.get('MenuId'), {
        reload: true
      }).then(menu => {
        const pagesAliases = model.pages.reduce((accumulator, item) => {
          accumulator[item.get('Alias')] = true;
          return accumulator;
        }, {});
        model.menu = menu;
        menu.markMissingPages(pagesAliases);
        this.configurationService.set('mainMenu', menu.get('mainMenu'));
        this.configurationService.set('unlinkedMenu', menu.get('unlinkedMenu'));
        return model;
      }); // return all([
      // 	this.store.findRecord('menu', model.website.get('MenuId'), { reload: true }),
      // 	new Promise(resolve => {
      // 		appBootstrap.loadCSS({
      // 			// Add css prefix from loadCSS. result: css-e6-bootstrap
      // 			id: config.APP.E6_BOOTSTRAP_CSS_PREFIX,
      // 			href: this.get('previewService.bootstrapUrl'),
      // 			callback() {
      // 				resolve();
      // 			}
      // 		});
      // 	})
      // ]).then(([menu]) => {
      // 	const pagesAliases = model.pages.reduce((accumulator, item) => {
      // 		accumulator[item.get('Alias')] = true;
      // 		return accumulator;
      // 	}, {});
      // 	model.menu = menu;
      // 	menu.markMissingPages(pagesAliases);
      // 	this.configurationService.set('mainMenu', menu.get('mainMenu'));
      // 	this.configurationService.set('unlinkedMenu', menu.get('unlinkedMenu'));
      // 	return model;
      // });
    },

    /**
     * Build modules dependencies, to be more precise, build dependencies map of relations, which theme depends on
     * which module and 2-nd map that holds which themes belongs to which module
     *
     * theme-datasource1 ---(depends on)---> module-slideshow ---(has theme (fk))---> theme-slideshow-simple
     *                                                         \---(has theme (fk))---> theme-slideshow-carousel
     * @param {ModuleDependencyManager} dependencyManager - module dependency manager, for which dependencies will
     * be updated
     * @param {ModuleModel[]} modules - list of all modules
     * @param {ModuleThemeModel[]}themes - list of all module themes
     * @returns {void}
     */
    buildModulesDependencies(dependencyManager, modules, themes) {
      // Build dependency map
      themes.forEach(theme => {
        const depends = theme.get('DependsOn') || [];
        let module;
        depends.forEach(moduleName => {
          module = modules.findBy('Name', moduleName);
          dependencyManager.addThemeToModuleDependency(theme, module);
        });
        module = modules.findBy('id', theme.get('ModuleId'));

        if (module) {
          dependencyManager.addModuleThemeFKDependency(module, theme);
        }
      });
    },

    /**
     * Method provide some additional dependencies for module style model
     * @param {DS.Model<ModuleStyleModel>[]} moduleStyleModels - module style models
     * @param {DS.Model<WebsiteStyleModel>[]} globalVariablesModel - global style variables for website
     * @returns {void}
     * @private
     */
    _provideModuleStylesModelDependencies(moduleStyleModels, globalVariablesModel) {
      moduleStyleModels.forEach(model => {
        const moduleTheme = this.store.peekRecord('module-theme', model.get('ThemeId'));

        if (moduleTheme) {
          model.set('styleMap', moduleTheme.get('StyleMap'));
        }

        model.set('globalVariablesModel', globalVariablesModel);
      });
    }

  });

  _exports.default = _default;
});