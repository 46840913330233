define("builder/serializers/page-seo", ["exports", "@ember-data/serializer/rest", "builder/core/enumerators/variant-names"], function (_exports, _rest, _variantNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Page seo serializer
   */
  var _default = _rest.default.extend({
    /**
     * @property {String} primaryKey - primary key index
     */
    primaryKey: 'Id',

    /**
     * @inheritdoc
     */
    serialize(...args) {
      const json = this._super(...args); // Remove variant-name


      if (json.Structure) {
        json.Structure = json.Structure.map(item => ({
          template: item.template,
          filter: item.filter
        }));
      }

      return json;
    },

    /**
     * @inheritdoc
     */
    normalize(model, hash, ...args) {
      const structure = hash.Structure; // Add variant-name

      if (structure) {
        structure.forEach(item => {
          item['variant-name'] = _variantNames.PAGE_SEO_NAME;
        });
      }

      return this._super(model, hash, ...args);
    }

  });

  _exports.default = _default;
});